import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function App() {
  const [style, setStyle] = useState({});
  const [slimeParticles, setSlimeParticles] = useState([]);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  const handleMouseEnter = () => {
    const positions = [
      { x: Math.random() * -100, y: Math.random() * 150 },
      { x: Math.random() * 50, y: Math.random() * -200 },
      { x: Math.random() * -200, y: Math.random() * 100 },
      { x: Math.random() * 150, y: Math.random() * -50 },
    ];

    const newPosition = positions[Math.floor(Math.random() * positions.length)];
    setStyle({
      transform: `translate(${newPosition.x}%, ${newPosition.y}%) scale(1.6)`,
    });

    const newParticles = Array.from({ length: 20 }, () => ({
      id: Math.random(),
      size: Math.random() * 20 + 5,
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      duration: Math.random() * 3 + 2,
    }));

    setSlimeParticles(prevParticles => [...prevParticles, ...newParticles]);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSlimeParticles(prevParticles => prevParticles.slice(20));
    }, 2000);

    return () => clearTimeout(timer);
  }, [slimeParticles]);

  const handleLinkClick = (link) => {
    window.open(link, '_blank');
  };

  const calculateLetterOpacity = (letterPos, mousePos) => {
    const distance = Math.sqrt(
      Math.pow(letterPos.x - mousePos.x, 2) + 
      Math.pow(letterPos.y - mousePos.y, 2)
    );
    // Increased from 50 to 100 for larger radius
    const maxDistance = 100; 
    // Using Math.pow to make the text become bright more quickly
    return Math.max(0, Math.pow(1 - (distance / maxDistance), 0.5));
};

  const handleHoverTextMouseMove = (e) => {
    if (!containerRef.current) return;
    
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setMousePos({ x, y });
  };

  const text = "qrime capital";

  return (
    <div className="App font-link">
      <header className="App-header">
        <div 
          ref={containerRef}
          className="hover-text-container"
          onMouseMove={handleHoverTextMouseMove}
          onMouseLeave={() => setMousePos({ x: -1000, y: -1000 })}
        >
          {text.split('').map((char, index) => {
            const letterRect = containerRef.current?.children[index]?.getBoundingClientRect();
            const letterPos = letterRect ? {
              x: letterRect.left - containerRef.current.getBoundingClientRect().left + letterRect.width / 2,
              y: letterRect.top - containerRef.current.getBoundingClientRect().top + letterRect.height / 2
            } : { x: 0, y: 0 };
            
            const opacity = calculateLetterOpacity(letterPos, mousePos);
            
            return (
              <span 
                key={index} 
                className={`hover-letter ${opacity > 0 ? 'visible' : ''}`}
                style={{
                  '--opacity': opacity
                }}
              >
                {char}
              </span>
            );
          })}
        </div>
        {slimeParticles.map(particle => (
          <div
            key={particle.id}
            className="slime-particle"
            style={{
              width: `${particle.size}px`,
              height: `${particle.size}px`,
              left: `${particle.x}px`,
              top: `${particle.y}px`,
              animation: `float ${particle.duration}s linear`,
              '--x': `${Math.random() * 200 - 100}px`,
              '--y': `${Math.random() * 200 - 100}px`,
            }}
          />
        ))}
        <p
          className="text"
          style={style}
          onClick={() => handleLinkClick('https://x.com/TheLabProtocol')}
          onMouseEnter={handleMouseEnter}
        >
          🧪
        </p>
      </header>
    </div>
  );
}

export default App;